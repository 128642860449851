<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col>
          <h1>{{ customer.full_name }}</h1>
        </v-col>
        <v-col>
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              v-if="customer.breeder"
              depressed
              color="warning"
              @click="confirmDialog.open = true"
            >
              <v-icon left>mdi-account-convert</v-icon>Switch Breeder Back To
              Customer
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <div>
        <v-row>
          <v-col cols="6">
            <v-card v-if="customer.breeder">
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Basic Details</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn depressed small @click="editCustomerDialog.open = true">
                  <v-icon left>mdi-pencil</v-icon>Edit
                </v-btn>
              </v-toolbar>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>First Name</th>
                    <td>{{ customer.first_name }}</td>
                  </tr>
                  <tr>
                    <th>Last Name</th>
                    <td>{{ customer.last_name }}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{{ customer.email }}</td>
                  </tr>
                  <tr>
                    <th>Phone</th>
                    <td>{{ customer.phone || "N/A" }}</td>
                  </tr>
                  <tr>
                    <th>Marketing Opt In</th>
                    <td>
                      {{ customer.marketing_opt_in === 1 ? "Yes" : "No" }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Addresses</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn depressed small @click="addressDialog.open = true"
                  >Add New Address</v-btn
                >
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    xl="6"
                    v-for="address in customer.addresses"
                    v-bind:key="address.id"
                  >
                    <v-card outlined>
                      <v-card-text>
                        <div
                          class="mb-3"
                          v-if="
                            customer.billing_address === address.id ||
                            customer.shipping_address === address.id
                          "
                        >
                          <v-chip
                            small
                            label
                            color="success"
                            v-if="customer.shipping_address === address.id"
                            class="mr-3"
                            >Delivery Address</v-chip
                          >
                          <v-chip
                            small
                            label
                            color="warning"
                            v-if="customer.billing_address === address.id"
                            >Billing Address</v-chip
                          >
                        </div>
                        <div>
                          <strong>{{ address.line_1 }}</strong>
                        </div>
                        <div v-if="address.line_2">{{ address.line_2 }}</div>
                        <div v-if="address.line_3">{{ address.line_3 }}</div>
                        <div>{{ address.city }}</div>
                        <div v-if="address.county">{{ address.county }}</div>
                        <div>{{ address.postcode }}</div>
                        <div v-if="address.country">{{ address.country }}</div>
                      </v-card-text>
                      <v-card-actions
                        v-if="
                          customer.billing_address !== address.id ||
                          customer.shipping_address !== address.id
                        "
                      >
                        <v-btn
                          small
                          outlined
                          color="success"
                          v-if="customer.shipping_address !== address.id"
                          :loading="changingDelivery === address.id"
                          @click="setAddress(address.id, 'delivery')"
                          >Set as Delivery</v-btn
                        >
                        <v-btn
                          small
                          outlined
                          color="warning"
                          v-if="customer.billing_address !== address.id"
                          :loading="changingBilling === address.id"
                          @click="setAddress(address.id, 'billing')"
                          >Set as Billing</v-btn
                        >
                      </v-card-actions>
                      <v-card-actions>
                        <v-btn
                          small
                          depressed
                          color="info"
                          @click="openEditAddress(address)"
                          >Edit</v-btn
                        >
                        <v-btn
                          small
                          depressed
                          color="error"
                          @click="deleteAddress(address)"
                          >Delete</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" xl="6">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Subscriptions</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                :headers="subscriptionHeaders"
                :items="customer.subscriptions"
                :footer-props="footerProps"
                no-data-text="No subscriptions found"
              >
                <template v-slot:item.identifier="{ item }">
                  <router-link
                    :to="{
                      name: 'module-paw-subscriptions-individual',
                      params: { subscriptionId: item.id },
                    }"
                    >{{ item.identifier }}</router-link
                  >
                </template>
                <template v-slot:item.frequency="{ item }">
                  {{ item.frequency_value }}
                  {{ item.frequency_unit }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        x-small
                        depressed
                        color="green lighten-4 green--text"
                        v-on="on"
                        :to="{
                          name: 'module-paw-subscriptions-individual',
                          params: { subscriptionId: item.id },
                        }"
                      >
                        <v-icon x-small>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>View</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12" xl="6">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Deliveries</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                :headers="deliveryHeaders"
                :items="deliveries"
                :footer-props="footerProps"
                no-data-text="No deliveries found"
                :loading="deliveriesLoading"
              >
                <template v-slot:item.formattedId="{ item }">
                  <router-link
                    :to="{
                      name: 'module-paw-deliveries-individual',
                      params: { deliveryId: item.id },
                    }"
                    >{{ item.formattedId }}</router-link
                  >
                </template>
                <template v-slot:item.cost="{ item }"
                  >£{{ item.cost }}</template
                >
                <template v-slot:item.status="{ item }">
                  <v-chip
                    small
                    label
                    :color="getDeliveryStatusColor(item.delivery_status)"
                    >{{ getDeliveryStatusLabel(item.delivery_status) }}</v-chip
                  >
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        x-small
                        depressed
                        color="green lighten-4 green--text"
                        v-on="on"
                        :to="{
                          name: 'module-paw-deliveries-individual',
                          params: { deliveryId: item.id },
                        }"
                      >
                        <v-icon x-small>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>View</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12" xl="6">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Breeder Details</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  small
                  @click="$refs.breederForm.openEditBreeder(customer.breeder)"
                >
                  <v-icon left>mdi-pencil</v-icon>Edit
                </v-btn>
              </v-toolbar>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>Business/Kennel Name</th>
                    <td>{{ customer.breeder.business_name }}</td>
                  </tr>
                  <tr>
                    <th>Date Joined</th>
                    <td>{{ customer.breeder.formatted_dates.joined_at }}</td>
                  </tr>
                  <tr>
                    <th>Available Credit</th>
                    <td>£{{ customer.breeder.available_credit }}</td>
                  </tr>
                  <tr>
                    <th>Lifetime Credit Earned</th>
                    <td>£{{ customer.breeder.lifetime_credit }}</td>
                  </tr>
                  <tr>
                    <th>Breeder Credit Per Code Used</th>
                    <td>
                      {{
                        customer.breeder.breeder_credited_type == "number"
                          ? "£" + customer.breeder.breeder_credited_amount
                          : customer.breeder.breeder_credited_amount + "%"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Customer Discount</th>
                    <td>
                      {{
                        customer.breeder.customer_discount_type == "number"
                          ? "£" + customer.breeder.customer_discount_amount
                          : customer.breeder.customer_discount_amount + "%"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Breeder Discount</th>
                    <td>
                      {{
                        customer.breeder.breeder_discount_type == "number"
                          ? "£" + customer.breeder.breeder_discount_amount
                          : customer.breeder.breeder_discount_amount + "%"
                      }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Discount Codes</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                :headers="discountCodesTable"
                :items="customer.breeder.codes"
                :footer-props="footerProps"
                no-data-text="No Discount Codes Sent Out"
              >
                <template v-slot:item.discount="{ item }">
                  {{
                    item.discount_code.discount_type == "number"
                      ? "£" + item.discount_code.discount_amount
                      : item.discount_code.discount_amount + "%"
                  }}
                </template>

                <template v-slot:item.code_used="{ item }">
                  <v-chip v-if="item.claimed_at" label small color="success"
                    >Yes</v-chip
                  >
                  <v-chip v-else label small color="error">No</v-chip>
                </template>
                <template v-slot:item.breeder_credit="{ item }">
                  £{{ item.breeder_credited_amount }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="!item.claimed_at"
                        x-small
                        depressed
                        color="orange lighten-4 orange--text"
                        v-on="on"
                        @click="openResendCode(item)"
                      >
                        <v-icon x-small>mdi-email-arrow-right</v-icon>
                      </v-btn>
                    </template>
                    <span>Resend Code Email</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="!item.claimed_at"
                        x-small
                        depressed
                        color="red lighten-4 red--text"
                        v-on="on"
                        class="ml-2"
                        @click="openDeleteCode(item)"
                      >
                        <v-icon x-small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Code</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Rewards History</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                :headers="rewardsHistoryTable"
                :items="rewardsHistory"
                :footer-props="footerProps"
                no-data-text="No Breeder History"
              >
                <template v-slot:item.earned_lost="{ item }">
                  {{ item.is_withdrawal ? "-" : "+" }} £{{ item.credit_change }}
                </template>
                <template v-slot:item.available_credits="{ item }">
                  £{{ item.available_credits }}
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <v-dialog v-model="deleteAddressDialog.open" max-width="400px">
      <v-card>
        <v-card-title class="headline"
          >Delete {{ deleteAddressDialog.addressLine1 }}</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-alert type="error" dense v-if="deleteAddressDialog.error">
            There was an error when attempting to delete this address. Please
            make sure that this address isn't either the Delivery or Billing
            Address. </v-alert
          >Are you sure you want to delete this address?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="closeDeleteAddress()"
            >No, Cancel</v-btn
          >
          <v-btn
            color="accent"
            text
            @click="confirmDeleteAddress()"
            :loading="deleteAddressDialog.loading"
            >Yes, Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteCodeDialog.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Code</v-card-title>
        <v-card-text>Are you sure you wish to archive ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteCode"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteCodeDialog.loading"
            @click="saveDeleteCode"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="resendCodeDialog.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Resend Code</v-card-title>
        <v-card-text>Are you sure you wish to resend code ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetResendCode"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="resendCodeDialog.loading"
            @click="resendCode"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addressDialog.open"
      max-width="600px"
      @click:outside="resetAddressForm()"
    >
      <v-card>
        <v-form @submit.prevent="saveAddress" method="post">
          <v-card-title>
            <span class="headline"
              >{{
                addressDialog.isEditing
                  ? "Edit " + addressDialog.address.line_1
                  : "Add New"
              }}
              Address</span
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              label="Address Line 1"
              type="text"
              v-model="addressDialog.fields.line_1"
              required
              outlined
              dense
              :error="addressDialog.errors.hasOwnProperty('line_1')"
              :error-messages="addressDialog.errors['line_1']"
            ></v-text-field>
            <v-text-field
              label="Address Line 2"
              type="text"
              v-model="addressDialog.fields.line_2"
              required
              outlined
              dense
              :error="addressDialog.errors.hasOwnProperty('line_2')"
              :error-messages="addressDialog.errors['line_2']"
            ></v-text-field>
            <v-text-field
              label="Address Line 3"
              type="text"
              v-model="addressDialog.fields.line_3"
              required
              outlined
              dense
              :error="addressDialog.errors.hasOwnProperty('line_3')"
              :error-messages="addressDialog.errors['line_3']"
            ></v-text-field>
            <v-text-field
              label="City"
              type="text"
              v-model="addressDialog.fields.city"
              required
              outlined
              dense
              :error="addressDialog.errors.hasOwnProperty('city')"
              :error-messages="addressDialog.errors['city']"
            ></v-text-field>
            <v-text-field
              label="County"
              type="text"
              v-model="addressDialog.fields.county"
              required
              outlined
              dense
              :error="addressDialog.errors.hasOwnProperty('county')"
              :error-messages="addressDialog.errors['county']"
            ></v-text-field>
            <v-text-field
              label="Postcode"
              type="text"
              v-model="addressDialog.fields.postcode"
              required
              outlined
              dense
              :error="addressDialog.errors.hasOwnProperty('postcode')"
              :error-messages="addressDialog.errors['postcode']"
            ></v-text-field>
            <v-text-field
              label="Country"
              type="text"
              v-model="addressDialog.fields.country"
              required
              outlined
              dense
              :error="addressDialog.errors.hasOwnProperty('country')"
              :error-messages="addressDialog.errors['country']"
            ></v-text-field>
            <v-checkbox
              v-model="addressDialog.fields.is_shipping"
              label="Set as Delivery Address"
              hide-details
              v-if="!addressDialog.isEditing"
            ></v-checkbox>
            <v-checkbox
              v-model="addressDialog.fields.is_billing"
              label="Set as Billing Address"
              hide-details
              v-if="!addressDialog.isEditing"
            ></v-checkbox>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="resetAddressForm()"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="addressDialog.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editCustomerDialog.open" max-width="600px">
      <v-card>
        <v-form @submit.prevent="editCustomer" method="post">
          <v-card-title>
            <span class="headline">Edit {{ customer.full_name }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="6" class="pb-0">
                <v-text-field
                  label="First Name*"
                  type="text"
                  v-model="editCustomerDialog.first_name"
                  required
                  outlined
                  dense
                  :error="
                    editCustomerDialog.errors.hasOwnProperty('first_name')
                  "
                  :error-messages="editCustomerDialog.errors['first_name']"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pb-0">
                <v-text-field
                  label="Last Name*"
                  type="text"
                  v-model="editCustomerDialog.last_name"
                  required
                  outlined
                  dense
                  :error="editCustomerDialog.errors.hasOwnProperty('last_name')"
                  :error-messages="editCustomerDialog.errors['last_name']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-text-field
              label="Email*"
              type="email"
              v-model="editCustomerDialog.email"
              required
              outlined
              dense
              :error="editCustomerDialog.errors.hasOwnProperty('email')"
              :error-messages="editCustomerDialog.errors['email']"
            ></v-text-field>
            <v-text-field
              label="Phone"
              type="tel"
              v-model="editCustomerDialog.phone"
              required
              outlined
              dense
              :error="editCustomerDialog.errors.hasOwnProperty('phone')"
              :error-messages="editCustomerDialog.errors['phone']"
            ></v-text-field>
            <v-checkbox
              v-model="editCustomerDialog.marketing_opt_in"
              label="Marketing Opt In"
              hide-details
              class="mt-0"
            ></v-checkbox>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="editCustomerDialog.open = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="editCustomerDialog.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog.open" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Confirm</v-card-title>
        <v-card-text
          >Are you sure you want to change this breeder back to a regular
          customer?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="confirmDialog.open = false"
            >No, cancel</v-btn
          >
          <v-btn color="success" depressed @click="confirmSwitchBreeder()">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.open" :timeout="snackbar.timeout">
      {{ snackbar.text }}
    </v-snackbar>

    <BreederForm ref="breederForm" />
  </div>
</template>

<script>
import BreederForm from "./components/BreederForm";

export default {
  components: {
    BreederForm,
  },

  data() {
    return {
      max: new Date().toISOString().slice(0, 10),
      footerProps: { "items-per-page-options": [5, 10, 25, 50, 100, -1] },
      deliveries: [],
      deliveriesLoading: true,
      breadcrumbs: [
        {
          text: "Paw Dog Food",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Breeders",
          disabled: false,
          exact: true,
          to: {
            name: "module-paw-breeders",
            params: { id: this.$route.params.id },
          },
        },
      ],
      subscriptionHeaders: [
        { text: "Ref No", value: "identifier" },
        { text: "Frequency", value: "frequency" },
        { text: "Status", value: "status" },
        { text: "Start Date", value: "formatted_dates.start_date" },
        { text: "Actions", value: "actions", align: "right" },
      ],
      deliveryHeaders: [
        { text: "Ref No", value: "formattedId" },
        { text: "Payment Date", value: "formatted_dates.process_date" },
        {
          text: "Estimated Delivery Date",
          value: "formatted_dates.estimated_delivery",
        },
        { text: "Cost", value: "cost" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", align: "right" },
      ],
      deliveryStatuses: [
        { value: "scheduled", label: "Scheduled", color: "blue white--text" },
        {
          value: "manually_paused",
          label: "Manually Paused",
          color: "red white--text",
        },
        {
          value: "payment_failed",
          label: "Payment Failed",
          color: "red white--text",
        },
        {
          value: "paid_dispatched",
          label: "Paid & Dispatched",
          color: "yellow white--text",
        },
        { value: "complete", label: "Complete", color: "green white--text" },
        { value: "cancelled", label: "Cancelled", color: "grey" },
        { value: "refunded", label: "Refunded", color: "red white--text" },
      ],
      deleteAddressDialog: {
        open: false,
        error: false,
        addressLine1: "",
        addressId: null,
        loading: false,
      },
      deleteCodeDialog: {
        dialog: false,
        code: {},
        loading: false,
      },
      resendCodeDialog: { dialog: false, code: {}, loading: false },
      confirmDialog: {
        open: false,
        loading: false,
      },
      addressDialog: {
        open: false,
        errors: {},
        fields: {
          line_1: "",
          line_2: "",
          line_3: "",
          city: "",
          county: "",
          postcode: "",
          country: "",
          is_shipping: false,
          is_billing: false,
        },
        loading: false,
        isEditing: false,
        address: {},
      },
      editCustomerDialog: {
        open: false,
        errors: {},
        first_name: "",
        last_name: "",
        email: "",
        phone: null,
        marketing_opt_in: false,
        loading: false,
      },
      snackbar: {
        open: false,
        text: "",
        timeout: 4000,
      },
      discountCodesTable: [
        {
          text: "Date and Time Sent",
          value: "formatted_dates.created_at",
        },
        { text: "Email", value: "email" },
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Customer Discount", value: "discount" },
        {
          text: "Expiry Date",
          value: "discount_code.formatted_dates.expires_at",
        },
        { text: "Code Used", value: "code_used" },
        { text: "Breeder Credit", value: "breeder_credit" },
        { text: "Actions", value: "actions", align: "right", sortable: false },
      ],
      rewardsHistoryTable: [
        { text: "Date and Time", value: "formatted_dates.created_at" },
        { text: "Activity", value: "activity" },
        { text: "Earned/Lost", value: "earned_lost" },
        { text: "Details", value: "details" },
        { text: "Available Credit", value: "available_credits" },
      ],
      changingDelivery: null,
      changingBilling: null,
    };
  },

  computed: {
    customer() {
      return this.$store.state.paw["customer"];
    },

    rewardsHistory() {
      return this.$store.state.paw["rewardsHistory"];
    },
  },

  mounted() {
    this.formatDeliveries();

    this.editCustomerDialog.first_name = this.customer.first_name;
    this.editCustomerDialog.last_name = this.customer.last_name;
    this.editCustomerDialog.email = this.customer.email;
    this.editCustomerDialog.phone = this.customer.phone;
    this.editCustomerDialog.marketing_opt_in = this.customer.marketing_opt_in;
  },

  watch: {
    customer: function (customer) {
      this.editCustomerDialog.first_name = customer.first_name;
      this.editCustomerDialog.last_name = customer.last_name;
      this.editCustomerDialog.email = customer.email;
      this.editCustomerDialog.phone = customer.phone;
      this.editCustomerDialog.marketing_opt_in = customer.marketing_opt_in;
    },
  },

  methods: {
    formatDeliveries: function () {
      const subscriptions = this.customer.subscriptions;
      let deliveries = [];

      subscriptions.forEach((s) => {
        s.deliveries.forEach((delivery) => {
          let d = delivery;
          d.formattedId = "";
          d.formattedId = s.identifier.concat(delivery.identifier);
          deliveries.push(d);
        });
      });

      this.deliveries = deliveries;
      this.deliveriesLoading = false;
    },
    getDeliveryStatusLabel: function (value) {
      const status = this.deliveryStatuses.find((s) => s.value === value);

      return status.label;
    },
    getDeliveryStatusColor: function (value) {
      const status = this.deliveryStatuses.find((s) => s.value === value);

      return status.color;
    },
    deleteAddress: function (address) {
      this.deleteAddressDialog.open = true;
      this.deleteAddressDialog.addressLine1 = address.line_1;
      this.deleteAddressDialog.addressId = address.id;
    },

    openDeleteCode(code) {
      this.deleteCodeDialog.dialog = true;
      this.deleteCodeDialog.code = code;
    },

    resetDeleteCode() {
      this.deleteCodeDialog.dialog = false;
      this.deleteCodeDialog.code = {};
      this.deleteCodeDialog.loading = false;
    },

    openResendCode(code) {
      this.resendCodeDialog.dialog = true;
      this.resendCodeDialog.code = code;
    },

    resendCode() {
      this.resendCodeDialog.loading = true;
      this.$store
        .dispatch("paw/resendCode", {
          appId: this.$route.params.id,
          codeId: this.resendCodeDialog.code.id,
        })
        .then(() => {
          this.resetResendCode();
        })
        .catch(() => {
          this.resendCodeDialog.loading = false;
        });
    },

    resetResendCode() {
      this.resendCodeDialog.dialog = false;
      this.resendCodeDialog.code = {};
      this.resendCodeDialog.loading = false;
    },

    saveDeleteCode() {
      this.deleteCodeDialog.loading = true;
      this.$store
        .dispatch("paw/deleteCode", {
          appId: this.$route.params.id,
          codeId: this.deleteCodeDialog.code.id,
          isOnBreederPage: true,
        })
        .then(() => {
          this.resetDeleteCode();
        })
        .catch(() => {
          this.deleteCodeDialog.loading = false;
        });
    },
    closeDeleteAddress: function () {
      this.deleteAddressDialog = {
        open: false,
        error: false,
        addressLine1: "",
        addressId: null,
        loading: false,
      };
    },
    confirmDeleteAddress: function () {
      const addressId = this.deleteAddressDialog.addressId;
      const appId = this.$route.params.id;
      const customerId = parseInt(this.$route.params.customerId);
      this.deleteAddressDialog.loading = true;

      this.$store
        .dispatch("paw/deleteAddress", { appId, addressId, customerId })
        .then(() => {
          this.deleteAddressDialog = {
            open: false,
            error: false,
            addressLine1: "",
            addressId: null,
            loading: false,
          };
        })
        .catch(() => {
          this.deleteAddressDialog.loading = false;
          this.deleteAddressDialog.error = true;
        });
    },
    setAddress: function (addressId, type) {
      if (type === "delivery") {
        this.changingDelivery = addressId;
      } else {
        this.changingBilling = addressId;
      }

      const appId = this.$route.params.id;
      const customerId = this.$route.params.customerId;

      this.$store
        .dispatch("paw/setAddress", { appId, addressId, customerId, type })
        .then(() => {
          this.changingBilling = null;
          this.changingDelivery = null;
        })
        .catch(() => {
          this.changingBilling = null;
          this.changingDelivery = null;
        });
    },
    confirmSwitchBreeder: function () {
      this.confirmDialog.open = false;
      this.confirmDialog.loading = true;
      this.confirmDialog.errors = {};

      const appId = this.$route.params.id;
      const customerId = parseInt(this.$route.params.customerId);

      this.$store
        .dispatch("paw/archiveBreeder", { appId, customerId })
        .then(() => {
          this.$router.push({
            name: "module-paw-customers",
          });
        })
        .catch(() => {
          this.confirmDialog.loading = false;
          this.confirmDialog.error = true;
        });
    },
    saveAddress: function () {
      const appId = this.$route.params.id;
      const customerId = this.$route.params.customerId;
      this.addressDialog.loading = true;
      this.addressDialog.errors = {};

      let payload = {
        appId,
        customerId,
        ...this.addressDialog.fields,
        isEditing: false,
      };

      if (this.addressDialog.isEditing) {
        payload.isEditing = true;
        payload.addressId = this.addressDialog.address.id;
      }

      this.$store
        .dispatch("paw/saveAddress", {
          ...payload,
        })
        .then(() => {
          this.resetAddressForm();
        })
        .catch((err) => {
          this.addressDialog.errors = err.response.data.errors;
          this.addressDialog.loading = false;
        });
    },
    openEditAddress: function (address) {
      this.addressDialog.fields.line_1 = address.line_1;
      this.addressDialog.fields.line_2 = address.line_2;
      this.addressDialog.fields.line_3 = address.line_3;
      this.addressDialog.fields.city = address.city;
      this.addressDialog.fields.county = address.county;
      this.addressDialog.fields.postcode = address.postcode;
      this.addressDialog.fields.country = address.country;

      if (this.customer.shipping_address === address.id) {
        this.addressDialog.fields.is_shipping = true;
      }
      if (this.customer.billing_address === address.id) {
        this.addressDialog.fields.is_billing = true;
      }

      this.addressDialog.isEditing = true;
      this.addressDialog.address = address;
      this.addressDialog.open = true;
    },

    editCustomer: function () {
      const appId = this.$route.params.id;
      const customerId = this.$route.params.customerId;
      this.editCustomerDialog.loading = true;
      this.editCustomerDialog.errors = {};

      this.$store
        .dispatch("paw/editCustomer", {
          appId,
          customerId,
          ...this.editCustomerDialog,
        })
        .then(() => {
          (this.editCustomerDialog.open = false),
            (this.editCustomerDialog.errors = {}),
            (this.editCustomerDialog.loading = false);
        })
        .catch((err) => {
          this.editCustomerDialog.errors = err.response.data.errors;
          this.editCustomerDialog.loading = false;
        });
    },

    resetAddressForm: function () {
      this.addressDialog = {
        open: false,
        errors: {},
        fields: {
          line_1: "",
          line_2: "",
          line_3: "",
          city: "",
          county: "",
          postcode: "",
          country: "",
          is_shipping: false,
          is_billing: false,
        },
        loading: false,
        isEditing: false,
        address: {},
      };
    },
  },
};
</script>
