<template>
  <v-dialog v-model="breederDialog.open" max-width="600px">
    <v-card>
      <v-form @submit.prevent="editBreeder" method="post">
        <v-card-title>
          <span class="headline">Edit Breeder Details</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Business/Kennel Name"
                type="text"
                v-model="breederDialog.fields.business_name"
                required
                outlined
                dense
                :error="breederDialog.errors.hasOwnProperty('business_name')"
                :error-messages="breederDialog.errors['business_name']"
              ></v-text-field>
              <v-text-field
                label="Date Joined"
                v-model="breederDialog.fields.joined_at"
                type="date"
                :max="max"
                outlined
                dense
                :error="breederDialog.errors.hasOwnProperty('joined_at')"
                :error-messages="breederDialog.errors['joined_at']"
              ></v-text-field>
              <v-text-field
                label="Available Credit"
                type="text"
                v-model="breederDialog.fields.available_credit"
                required
                outlined
                dense
                :error="breederDialog.errors.hasOwnProperty('available_credit')"
                :error-messages="breederDialog.errors['available_credit']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-text-field
            label="Lifetime Credit Earned"
            type="text"
            v-model="breederDialog.fields.lifetime_credit"
            required
            outlined
            dense
            :error="breederDialog.errors.hasOwnProperty('lifetime_credit')"
            :error-messages="breederDialog.errors['lifetime_credit']"
          ></v-text-field>
          <v-row>
            <v-col cols="2">
              <v-select
                label="Type"
                v-model="breederDialog.fields.breeder_credited_type"
                :items="types"
                dense
                outlined
                background-color="white"
                :error="
                  breederDialog.errors.hasOwnProperty('breeder_credited_type')
                "
                :error-messages="breederDialog.errors['breeder_credited_type']"
              ></v-select>
            </v-col>
            <v-col cols="10">
              <v-text-field
                label="Breeder Credit Per Code Used"
                type="text"
                v-model="breederDialog.fields.breeder_credited_amount"
                required
                outlined
                dense
                :error="
                  breederDialog.errors.hasOwnProperty('breeder_credited_amount')
                "
                :error-messages="
                  breederDialog.errors['breeder_credited_amount']
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-select
                label="Type"
                v-model="breederDialog.fields.customer_discount_type"
                :items="types"
                outlined
                dense
                background-color="white"
                :error="
                  breederDialog.errors.hasOwnProperty('customer_discount_type')
                "
                :error-messages="breederDialog.errors['customer_discount_type']"
              ></v-select>
            </v-col>
            <v-col cols="10">
              <v-text-field
                label="Customer Discount"
                type="text"
                v-model="breederDialog.fields.customer_discount_amount"
                required
                outlined
                dense
                :error="
                  breederDialog.errors.hasOwnProperty(
                    'customer_discount_amount'
                  )
                "
                :error-messages="
                  breederDialog.errors['customer_discount_amount']
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-select
                label="Type"
                v-model="breederDialog.fields.breeder_discount_type"
                :items="types"
                outlined
                dense
                background-color="white"
                :error="
                  breederDialog.errors.hasOwnProperty('breeder_discount_type')
                "
                :error-messages="breederDialog.errors['breeder_discount_type']"
              ></v-select>
            </v-col>
            <v-col cols="10">
              <v-text-field
                label="Breeder Discount"
                type="text"
                v-model="breederDialog.fields.breeder_discount_amount"
                outlined
                dense
                :error="
                  breederDialog.errors.hasOwnProperty('breeder_discount_amount')
                "
                :error-messages="
                  breederDialog.errors['breeder_discount_amount']
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="breederDialog.open = false"
            >Close</v-btn
          >
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="breederDialog.loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      types: [
        { text: "£", value: "number" },
        { text: "%", value: "percent" },
      ],
      max: new Date().toISOString().slice(0, 10),
      breederDialog: {
        open: false,
        errors: {},
        fields: {
          joined_at: null,
          available_credit: "",
          lifetime_credit: "",
          customer_discount: "",
          customer_discount_type: "",
          breeder_credited_type: "",
          breeder_credited_amount: "",
          breeder_discount: "",
          breeder_discount_type: "",
          business_name: "",
        },
        loading: false,
        isEditing: false,
        breeder: {},
      },
    };
  },

  methods: {
    openEditBreeder: function (breeder = null) {
      if (breeder !== null) {
        this.breederDialog.breeder = breeder;

        this.breederDialog.fields.joined_at = breeder.joined_at;
        this.breederDialog.fields.business_name = breeder.business_name;
        this.breederDialog.fields.available_credit = breeder.available_credit;
        this.breederDialog.fields.lifetime_credit = breeder.lifetime_credit;
        this.breederDialog.fields.customer_discount_amount =
          breeder.customer_discount_amount;
        this.breederDialog.fields.customer_discount_type =
          breeder.customer_discount_type;
        this.breederDialog.fields.breeder_credited_amount =
          breeder.breeder_credited_amount;
        this.breederDialog.fields.breeder_credited_type =
          breeder.breeder_credited_type;
        this.breederDialog.fields.breeder_discount_amount =
          breeder.breeder_discount_amount;
        this.breederDialog.fields.breeder_discount_type =
          breeder.breeder_discount_type;

        this.breederDialog.isEditing = true;
      }

      this.breederDialog.open = true;
    },
    editBreeder() {
      this.breederDialog.loading = true;
      this.breederDialog.errors = {};

      this.breederDialog.fields.customer_id = this.$route.params.customerId;

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.breederDialog.isEditing,
        fields: this.breederDialog.fields,
      };

      if (this.breederDialog.isEditing) {
        payload.customerId = this.breederDialog.breeder.customer_id;
      }

      this.$store
        .dispatch("paw/saveBreeder", payload)
        .then(() => {
          this.resetBreederForm();
        })
        .catch((err) => {
          this.breederDialog.errors = err.response.data.errors;
          this.breederDialog.loading = false;
        });
    },

    resetBreederForm: function () {
      this.breederDialog = {
        open: false,
        errors: {},
        fields: {
          joined_at: null,
          available_credit: "",
          lifetime_credit: "",
          customer_discount: "",
          customer_discount_type: "",
          breeder_credited_type: "",
          breeder_credited_amount: "",
          breeder_discount: "",
          breeder_discount_type: "",
          business_name: "",
        },
        loading: false,
        isEditing: false,
        breeder: {},
      };
    },
  },
};
</script>
